<template>
     <div>
         <a-page-header
            :title="$t('newslist.title')"
            class="header_title"
        />
         <router-view></router-view>
        <div class='box_content orders'>
            <a-button @click="addNewsBtn" type="danger">
            {{$t('newslist.btn_add')}}
          </a-button>
              <a-table @change="pageChange" :pagination="pagination" :rowKey="(record, index) => index" :row-selection="rowSelection" :columns="columns" :data-source="pressesData" :loading="loading">
                <img class="proimg" slot="img" slot-scope="img" :src='img' alt="error" />
                <a-tag
                    slot="status" slot-scope="text"
                    :color="'geekblue'"
                  >
                    {{ text}}
                </a-tag>
                <span slot="press_id" slot-scope="text,data,index">
                      <a @click="editBtn(data.press_id)">{{$t('action.edit')}}</a>
                      <a-divider type="vertical" />
                      <a @click="deleteBtn(data.press_id,index)">{{$t('action.delete')}}</a>
                </span>
            
            </a-table>
        </div>
    </div>
</template>

<script>
import {getPresses,deletePress} from '@/network/pressrequest'
export default {
    name: 'NewsList',
    components: {  },
    directives: {  },
    data() {
        return {
             loading:false,
             pressesData:[],
            //  columns,
             pagination:{defaultPageSize:10,current:1,total:0}
        };
    },
    created() {
       this.getPressData(1)
    },
    computed:{
      columns(){
        return [
          {
            title: this.$t('newslist.table.head_titel_1'),
            dataIndex: 'img',
            scopedSlots: { customRender: 'img' },
          },
          {
            title: this.$t('newslist.table.head_titel_2'),
            dataIndex: 'title',
            scopedSlots: { customRender: 'title' },
          },
          {
            title: this.$t('newslist.table.head_titel_3'),
            dataIndex: 'author',
            scopedSlots: { customRender: 'author' },
          },
          {
            title: this.$t('newslist.table.head_titel_4'),
            dataIndex: 'keywords',
          },
          {
            title: this.$t('newslist.table.head_titel_5'),
            dataIndex: 'post_date',
          },
          // {
          //   title: this.$t('newslist.table.head_titel_6'),
          //   dataIndex: 'status',
          //   scopedSlots: { customRender: 'status' },
          // },
          {
            title: this.$t('newslist.table.head_titel_7'),
            dataIndex: 'press_id',
            scopedSlots: { customRender: 'press_id' },
          },
        ];
      },
       rowSelection() {
          return {
            onChange: (selectedRowKeys, selectedRows) => {
              console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            getCheckboxProps: record => ({
              props: {
                disabled: record.name === 'Disabled User', // Column configuration not to be checked
                name: record.name,
              },
            }),
          };
        },
    },
    methods: {
        getPressData(page){
          this.loading = true;
           getPresses(page)
            .then(res=>{
              this.loading = false;
              if(res.header.code==200){
                this.pressesData=page===1?res.body.presses:this.pressesData.concat(res.body.presses)
                this.$set(this.pagination,"total",res.body.total_rows);
                let fornum=Math.ceil(res.body.total_rows/10)//计算一共有多少页总页数/每页10
                if(page!=fornum&&fornum>0){
                  this.getPressData(page+1)
                }
              }
            
            })
        },
        pageChange(page){
          this.pagination=page
        },
        addNewsBtn(){
          this.$router.push('/pressandnews/editnews')
        },
        editBtn(e){
            this.$router.push({path:`/pressandnews/editnews`,query:{press_id:e}})
        },
        deleteBtn(press_id,index){
          deletePress(press_id)
          .then(res=>{
            if(res.header.code==200){
              this.pressesData.splice(index,1)
              this.$message.success("delete success!")
            }
          })
        },
    },
}; 
</script>

<style  scoped>

.orders .wrap_order{box-shadow: 0 0 10px rgba(143, 144, 165, 0.7);padding: 15px 0;overflow-x: auto;}
.orders .ordertable{width: 100%;border-spacing: 0;}
.orders .proimg{width: 50px;height: 50px;}

</style>